// React
import { useEffect } from 'react';
// 外部ライブラリ
import { motion } from "framer-motion";
import { Link, } from 'react-router-dom';
// 内部ライブラリ
import { getParam } from 'modules/url'
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join, nl2br, unEscapeHtmlSpecialCharacters } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector, setDvh, setSvh } from 'modules/screen'
import { generateISObserver } from 'modules/ISObserver'
import useCurrentPage from 'hooks/useCurrentPage'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'

// コンポーネント
// 型定義
// 定数
// 画像
// import funclub_button_photo2 from 'assets/img/home/fanclub_photo2.png'
import funclub_button_photo2 from 'assets/img/home/fanclub_photo3.png'
import funclub_button_photo_small2 from 'assets/img/home/fanclub_photo_sp3.png'
// import funclub_button_photo_small2 from 'assets/img/home/fanclub_photo_sp3.png'


type Props = {
}

export default function VirtualEstateButton(props: Props) {

  /*=======================================
  スクロールによる表示非表示の制御
=======================================*/
  useEffect(() => {
    const button = qs("#l__funclub_button");

    const firstViewObserver = generateISObserver(
      '#first_view',
      { rootMargin: "-50% 0% 0% 0%", },
      () => { removeClass(button, "fv_scrolled") }, // FVと交差したら大きめ
      () => { addClass(button, "fv_scrolled") }, // FVと交差していなければ小さめ
    )

    // FVのアニメーションが終わるのを待ってからフッターとの交差を監視
    let footerObserver: IntersectionObserver | null = null;
    setTimeout(() => {
      footerObserver = generateISObserver(
        '#footer',
        { rootMargin: "0% 0% -0% 0%", },
        () => { removeClass(button, "active") }, // フッターと交差したら非表示
        () => { addClass(button, "active") }, // フッターと交差していなければ表示
      )
    }, 7000);

    return () => {
      footerObserver?.disconnect();
      firstViewObserver?.disconnect();
    };
  }, []);

  return (
    <>
      <div id="l__funclub_button" className={""} >
        <a href="https://special.cyberstar.jp/risa.tsumugi/fc/" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} target="_blank" rel="noopener noreferrer">
          <img src={funclub_button_photo2} alt="" className='photo large' />
          <img src={funclub_button_photo_small2} alt="" className='photo small' />
        </a>
      </div>
    </>
  );
}