// React
import { useEffect, useState } from 'react'
// 外部ライブラリ
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import axios from 'axios';
// 内部ライブラリ
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { nl2br } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector } from 'modules/screen'
import { commonAnimationObserver } from 'modules/ISObserver'
import { getNewsCategoryColorClass, historyCategoryColor, nl2li } from 'modules/projectModules'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// コンポーネント
import PrimaryHeading from 'components/headings/PrimaryHeading'
import History from './History'
// 型定義
// 定数
import { SITE_TITLE, SITE_URL_BASE, API_BASE_URL } from 'config/Constants';
// 画像
import profile from 'assets/img/profile/profile.png'

// その他
import VoiceSample from './VoiceSample';
import DecoCircle from './DecoCircle';

type Props = {
}

export default function Index(props: Props) {


  /*=======================================
    背景操作用
  =======================================*/

  // アニメーション用
  useEffect(() => {
    const observer = commonAnimationObserver();
    return () => {
      observer?.disconnect();
    };
  }, []);


  return (
    <>
      <Helmet>
        <title>{`Profile｜${SITE_TITLE}`}</title>
        <meta property="og:url" content={SITE_URL_BASE + "/profile"} />
        <meta property="og:title" content={`Profile｜${SITE_TITLE}`} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.5 }} // 切り替えに要する時間
      >
        <main id="p__profile" className="l__main_content">
          <PrimaryHeading>Profile</PrimaryHeading>
          <div className="profile_inner c__js_fade">

            <div className="profile_l">
              <div className="profile_image_wrapper">
                <img src={profile} alt="" className="profile_image" />
                <DecoCircle/>
              </div>
              <div className="introduction_wrapper">
                <p className="name">
                  <span className="ja">紡木吏佐</span>
                  <span className="en">Risa Tsumugi</span>
                </p>
                <div className="table">
                  <p className="head">
                    <span className="">生年月日</span>
                    <span className="">出身地</span>
                    <span className="">特技</span>
                    <span className="">趣味</span>
                    <span className="">所属</span>
                  </p>
                  <p className="content">
                    <span className="">7 月 5 日</span>
                    <span className="">沖縄県</span>
                    <span className="">英語、沖縄方言、DJ</span>
                    <span className="">神社巡り、御朱印集め</span>
                    <span className="">
                      JeAC<br />
                      <span className="multi_line_bottom">
                        mitt management(業務提携)
                        </span>
                    </span>
                  </p>
                </div>
              </div>

              <VoiceSample/>

            </div>
            <div className="profile_r">


              <History />


            </div>
          </div>
        </main>
      </motion.div>
    </>
  );
}