// React
import { useEffect } from 'react';
// 外部ライブラリ
import { Link, } from 'react-router-dom';
// 内部ライブラリ
import useCurrentPage from 'hooks/useCurrentPage'
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join } from 'modules/string'
import { loading, loaded, scrollTo, scrollToSelector, setDvh } from 'modules/screen'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
// コンポーネント
import SnsIconList from 'components/ui/SnsIconList';
import NavigationButton from 'components/ui/NavigationButton'
// 型定義
// 定数

type Props = {
  toggleMenu: any;
}

export default function SpMenu(props: Props) {

  const currentPage = useCurrentPage();

  /*=======================================
    クリック制御
  =======================================*/
  // 移動先を制御
  function handleClickLink(e: any, targetPage: string) {
    props.toggleMenu();
    if (targetPage === "home" && currentPage === "home") scrollTo(0)
  };

  return (
    <>
      {/* FVでヘッダーを非表示にしたいので始めからdisabledクラスを付与 */}
      <button id="header_toggle" onClick={props.toggleMenu} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
        <span></span>
        <span></span>
      </button>

      <nav id="sp_menu" >
        <div className="menu_inner">

          <Link to="/news" className="link_wrapper" onClick={(e) => handleClickLink(e, "home")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <span className="link_name">News</span>
            <NavigationButton variant="circle" />
          </Link>

          <Link to="/profile" className="link_wrapper" onClick={(e) => handleClickLink(e, "home_works")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <span className="link_name">Profile</span>
            <NavigationButton variant="circle" />
          </Link>

          <Link to="/schedule" className="link_wrapper" onClick={(e) => handleClickLink(e, "about")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <span className="link_name">Schedule</span>
            <NavigationButton variant="circle" />
          </Link>

          <a href="https://special.cyberstar.jp/risa.tsumugi/fc/" className="link_wrapper" target="_blank" rel="noopener noreferrer" onClick={props.toggleMenu} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <span className="link_name">Fanclub</span>
            <NavigationButton variant="circle" />
          </a>

          <Link to="/contact" className="link_wrapper" onClick={(e) => handleClickLink(e, "contact")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <span className="link_name">Contact</span>
            <NavigationButton variant="circle" />
          </Link>

          <SnsIconList variantClassName={"header"} />

        </div>


        <p className="sp_menu_bottom_copyright">
          ©︎ RisaTsumugi All Rights Reserved.
        </p>
      </nav>
    </>
  );
}