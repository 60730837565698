// React
// 外部ライブラリ
import { Link, useNavigate } from 'react-router-dom';
// 内部ライブラリ
import useCurrentPage from 'hooks/useCurrentPage'
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { loaded, scrollToSelector } from 'modules/screen'
import { join } from 'modules/string'
// コンポーネント
import SnsIconList from 'components/ui/SnsIconList';
// 型定義
// 定数




type Props = {
}
export default function Footer(props: Props) {

  const currentPage = useCurrentPage();
  const navigate = useNavigate();

  return (
    <>
      <footer id="footer" className={""}>

        <div className="footer_inner">

          <nav className="footer_l">

            <Link to="/news" className="footer_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              News
            </Link>

            <Link to="/profile" className="footer_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Profile
            </Link>

            <Link to="/schedule" className="footer_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Schedule
            </Link>

            <a href="https://special.cyberstar.jp/risa.tsumugi/fc/" className="footer_link" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Fanclub
            </a>

            <Link to="/contact" className="footer_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Contact
            </Link>

          </nav>

          <div className="footer_r">

            <SnsIconList />
            <div className="footer_r_bottom">
              <Link className="terms footer_link" to="/terms" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                Privacy policy
              </Link>
              <p className="copyright">
                ©︎ RisaTsumugi All Rights Reserved.
              </p>
            </div>


          </div>
        </div>
        <div className="footer_inner_sp">

          <p className="copyright_sp">
            ©︎ RisaTsumugi All Rights Reserved.
          </p>
        </div>

      </footer>


    </>
  );
}