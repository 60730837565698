// React
import { useEffect } from 'react';
// 外部ライブラリ
import { Link, useNavigate } from 'react-router-dom';
// 内部ライブラリ
import useCurrentPage from 'hooks/useCurrentPage'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { loading, loaded, scrollTo, scrollToSelector, setDvh, preventScroll, arrowScroll } from 'modules/screen'
import { qs, qsAll, hasClass, addClass, removeClass, addClassAll, removeClassAll, toggleClass } from "modules/qs";
import { join } from 'modules/string'
// コンポーネント
import SpMenu from './SpMenu';
import SnsIconList from 'components/ui/SnsIconList';

// 型定義
// 定数
// 画像
import symbol from 'assets/img/common/logo/rt_symbol.svg'
import SymbolMark from './SymbolMark';


type Props = {
}

let toggle: any;
let sp_menu: any;

export default function Header(props: Props) {

  const currentPage = useCurrentPage();


  /*=======================================
    トップ以外であれば初めからヘッダーを表示
  =======================================*/
  useEffect(() => {
    if (currentPage !== "home") {
      addClass("#header", 'active');
    };
  }, []);


  /*=======================================
    ロゴクリック時の制御
  =======================================*/
  function handleClickLogo() {
    if (currentPage === "home") {
      scrollTo(0);
    };
    removeClass(toggle, "active");
    removeClass(sp_menu, "active");
    arrowScroll();
  };



  /*=======================================
    SPメニュー制御
  =======================================*/

  // 要素取得
  useEffect(() => {
    toggle = qs("#header_toggle");
    sp_menu = qs("#sp_menu");
    return arrowScroll();
  }, []);

  // SPメニューの開閉を制御
  function toggleMenu() {
    setDvh();

    if (hasClass(toggle, "active")) {
      removeClass(toggle, "active");
      removeClass(sp_menu, "active");
      arrowScroll();
    } else {
      preventScroll();
      addClass(toggle, "active");
      addClass(sp_menu, "active");
    }
  }


  return (
    <>
      <header id={"header"}>

        <div className="header_symbol">

          <Link to="/" className="logo_anchor" onClick={() => handleClickLogo()} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor} >
            <SymbolMark/>
            {/* <img src={symbol} className="" alt="" width={70} height={70} /> */}
          </Link>

        </div>

        <div className={"header_inner " + (currentPage === "home" ? "" : " bg_active")}>

          <div className="header_l">

            <Link to="/news" className="header_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              News
            </Link>

            <Link to="/profile" className="header_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Profile
            </Link>

            <Link to="/schedule" className="header_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Schedule
            </Link>

            <a href="https://special.cyberstar.jp/risa.tsumugi/fc/" className="header_link" target="_blank" rel="noopener noreferrer" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Fanclub
            </a>

            <Link to="/contact" className="header_link" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              Contact
            </Link>

          </div>

          <nav className="header_r">

            <SnsIconList variantClassName={"header"} />

          </nav>
        </div>

        <SpMenu toggleMenu={toggleMenu} />
      </header>
    </>
  );
}